// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Form
import CreateAppointmentForm from "../../../components/CreateAppointmentForm";

// Routes
import routes from "routes";
// import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-rental.jpeg";
import { useNavigate } from "react-router-dom";

function CreateAppointment() {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/home");
  };
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-pro-react",
          label: "buy now",
          color: "info",
        }}
        transparent
        light
      />
      <MKBox
        minHeight="50vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.5),
              rgba(gradients.dark.state, 0.5)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <MKBox
          position="absolute"
          top="1rem"
          left="1rem"
          zIndex={10}
          sx={{
            "&:hover .signOutButton": {
              opacity: 1,
              visibility: "visible",
            },
          }}
        >
          <MKButton
            className="signOutButton"
            variant="gradient"
            color="light"
            size="small"
            sx={{
              padding: "0.5rem 1rem",
              margin: 0,
              opacity: 0,
              visibility: "hidden",
              transition: "opacity 0.3s ease, visibility 0.3s ease", // Smooth transition
            }}
            onClick={handleBack}
          >
            Back
          </MKButton>
        </MKBox>
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h2"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Plan Our Next Special Moment
            </MKTypography>
            <MKTypography variant="body1" color="white" mt={1}>
              Whether it&rsquo;s a cozy dinner or an adventurous trip, let&rsquo;s plan something
              unforgettable together.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          overflow: "hidden",
        }}
      >
        <CreateAppointmentForm />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        {/* <DefaultFooter content={footerRoutes} /> */}
      </MKBox>
    </>
  );
}

export default CreateAppointment;
