// src/api/auth.js
import axios from "./axiosConfig";

export const login = async (email, password) => {
  try {
    const response = await axios.post("/auth/login", { email, password });
    console.log("response.data: ", response.data);
    return response.data; // Return the response data
  } catch (error) {
    throw error.response.data; // Throw the error response for handling
  }
};
