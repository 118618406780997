// src/context/MemoryContext.js
import React, { createContext, useContext } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import {
  createMemory as apiCreateMemory,
  getMemoryPagination as apiGetMemoryPagination,
  getMemory as apiGetMemory,
} from "../api/memory";

const MemoryContext = createContext();

export const MemoryProvider = ({ children }) => {
  //   const [memory, setMemory] = useState(null);

  const createMemory = async (memoryData) => {
    try {
      console.log("memoryData in context: ", memoryData);

      // Log the contents of formData
      for (let [key, value] of memoryData.entries()) {
        console.log(`${key}:`, value);
      }

      const data = await apiCreateMemory(memoryData);
      console.log("data in create memory: ", data);
      return data;
    } catch (error) {
      console.error("Failed to create memory: ", error);
    }
  };

  const getMemory = async (id = null) => {
    console.log("START getMemory IN MemoryContext!");
    try {
      const data = await apiGetMemory(id);
      console.log("data in MemoryContext: ", data);
      return data;
    } catch (error) {
      console.error("Failed to fetch memory: ", error);
    }
    console.log("END getMemory IN MemoryContext!");
  };

  const getMemoryPagination = async (id = null, params = null) => {
    try {
      const data = await apiGetMemoryPagination(id, params); // Pass pagination params here
      // console.log("data in MemoryContext: ", data);
      return data;
    } catch (error) {
      // console.error("Failed to fetch memory: ", error);
    }
  };

  return (
    <MemoryContext.Provider value={{ createMemory, getMemoryPagination, getMemory }}>
      {children}
    </MemoryContext.Provider>
  );
};

MemoryProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useMemory = () => useContext(MemoryContext);
