import React, { useState, useEffect } from "react";
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import Grid from "@mui/material/Grid";

const AgeCounter = () => {
  const [ageDetails, setAgeDetails] = useState({ years: 0, months: 0, days: 0 });

  useEffect(() => {
    // Her birthdate (31st October 2000)
    const birthDate = new Date("2000-10-31");

    // Current date (today's date)
    const today = new Date();

    // Calculate the difference in years
    let years = today.getFullYear() - birthDate.getFullYear();

    // Calculate the difference in months
    let months = today.getMonth() - birthDate.getMonth();

    // Calculate the difference in days
    let days = today.getDate() - birthDate.getDate();

    // If the birthday hasn't occurred yet this year, adjust the age
    if (months < 0 || (months === 0 && days < 0)) {
      years--; // Decrease the age by 1 year if the birthday is upcoming this year
      months += 12; // Adjust months
    }

    // If days are negative, adjust the month
    if (days < 0) {
      months--;
      // Get the number of days in the previous month
      const lastMonth = new Date(today.getFullYear(), today.getMonth(), 0).getDate();
      days += lastMonth; // Add the days of the previous month
    }

    // Set the calculated age details (years, months, days)
    setAgeDetails({ years, months, days });
  }, []); // Empty dependency array ensures this runs once on mount

  return (
    <Grid item xs={12} md={4}>
      <DefaultCounterCard
        count={ageDetails.years} // Only display the number of years in the count
        title="Years Old"
        description={`Celebrating ${ageDetails.years} years, ${ageDetails.months} months, and ${ageDetails.days} days of life.`} // Detailed age information
      />
    </Grid>
  );
};

export default AgeCounter;
