// //! ==========================================================================================================================================================

// src/Pages/Home/components/Blog/index.js

// import { useEffect, useState } from "react";
// // import { useNavigate } from "react-router-dom";

// // @mui material components
// import Container from "@mui/material/Container";
// import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";

// // Material Kit 2 PRO React components
// import MKBox from "components/MKBox";
// import MKPagination from "components/MKPagination";

// // Context
// import { useMemory } from "context/MemoryContext";

// // Components
// import SimpleBookingCard from "examples/Cards/BookingCards/SimpleBookingCard";

// function Blog() {
//   const [memories, setMemories] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1); // Current page number
//   const [totalPages, setTotalPages] = useState(1); // Total number of pages
//   const { getMemoryPagination } = useMemory();
//   // const navigate = useNavigate(); // Use navigate hook from React Router

//   // Fetch memories with pagination
//   const fetchMemories = async (page = 1) => {
//     setLoading(true);
//     try {
//       const params = {
//         limit: 6, // Limit to 6 memories per page
//         page, // Pass the current page
//       };

//       const response = await getMemoryPagination(null, { params });
//       console.log("response fetchMemories: ", response);

//       setMemories(response.data);
//       setTotalPages(response.totalPages); // Set the total number of pages
//       setCurrentPage(page); // Set the current page based on the response
//     } catch (error) {
//       console.error("Error fetching memories:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Fetch memories on component mount or page change
//   useEffect(() => {
//     fetchMemories(1); // Initial page load fetch (page 1)
//   }, []);

//   // Handle page change (next, previous, or specific page)
//   const handlePageChange = (page) => {
//     if (page <= totalPages && page >= 1) {
//       fetchMemories(page);
//     }
//   };

//   // Navigate to memory details when "View Details" is clicked
//   const handleViewDetails = (memoryId) => {
//     console.log("memoryId: ", memoryId);
//     // navigate(`/memory/details/${memoryId}`);
//   };

//   return (
//     <>
//       <MKBox component="section" py={3}>
//         <Container>
//           <Grid container spacing={3} sx={{ mt: 3 }}>
//             {loading ? (
//               <p>Loading...</p>
//             ) : (
//               memories.map((memory, index) => (
//                 <Grid item xs={12} md={6} lg={4} key={index}>
//                   <MKBox mt={3}>
//                     <SimpleBookingCard
//                       image={memory.files[0].url} // Use the first image from the files array
//                       title={memory.memoryName}
//                       description={memory.description}
//                       action={{
//                         type: "internal",
//                         route: `/memory/details/${memory._id}`,
//                         color: "info",
//                         label: "View Details",
//                         onClick: () => handleViewDetails(memory._id), // Pass memory ID to navigate
//                       }}
//                     />
//                   </MKBox>
//                 </Grid>
//               ))
//             )}
//           </Grid>

//           {/* Pagination Controls */}
//           <MKBox mt={5}>
//             <MKPagination>
//               {/* Previous Page */}
//               <MKPagination
//                 item
//                 disabled={currentPage === 1} // Disable on first page
//                 onClick={() => handlePageChange(currentPage - 1)}
//               >
//                 <Icon>keyboard_arrow_left</Icon>
//               </MKPagination>

//               {/* Display page numbers */}
//               {Array.from({ length: totalPages }, (_, i) => (
//                 <MKPagination
//                   item
//                   key={i + 1}
//                   active={i + 1 === currentPage} // Highlight current page
//                   onClick={() => handlePageChange(i + 1)} // Fetch page by number
//                 >
//                   {i + 1}
//                 </MKPagination>
//               ))}

//               {/* Next Page */}
//               <MKPagination
//                 item
//                 disabled={currentPage === totalPages} // Disable on last page
//                 onClick={() => handlePageChange(currentPage + 1)}
//               >
//                 <Icon>keyboard_arrow_right</Icon>
//               </MKPagination>
//             </MKPagination>
//           </MKBox>
//         </Container>
//       </MKBox>
//     </>
//   );
// }

// export default Blog;

//! ==========================================================================================================================================================

import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKPagination from "components/MKPagination";

// Context
import { useMemory } from "context/MemoryContext";

// Components
import SimpleBookingCard from "examples/Cards/BookingCards/SimpleBookingCard";

function Blog() {
  const [memories, setMemories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [totalPages, setTotalPages] = useState(1); // Total number of pages
  const { getMemoryPagination } = useMemory();
  // const navigate = useNavigate(); // Use navigate hook from React Router

  // Fetch memories with pagination
  const fetchMemories = async (page = 1) => {
    setLoading(true);
    try {
      const params = {
        limit: 6, // Limit to 6 memories per page
        page, // Pass the current page
      };

      const response = await getMemoryPagination(null, { params });
      // console.log("response fetchMemories: ", response);

      setMemories(response.data);
      setTotalPages(response.totalPages); // Set the total number of pages
      setCurrentPage(page); // Set the current page based on the response
    } catch (error) {
      console.error("Error fetching memories:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch memories on component mount or page change
  useEffect(() => {
    fetchMemories(currentPage); // Fetch memories based on current page
  }, [currentPage]); // Depend on currentPage

  // Handle page change (next, previous, or specific page)
  const handlePageChange = (page) => {
    if (page <= totalPages && page >= 1) {
      setCurrentPage(page); // Update current page
    }
  };

  // Navigate to memory details when "View Details" is clicked
  const handleViewDetails = (memoryId) => {
    console.log("memoryId: ", memoryId);
    // navigate(`/memory/details/${memoryId}`);
  };

  return (
    <>
      <MKBox component="section" py={3}>
        <Container>
          <Grid container spacing={3} sx={{ mt: 3 }}>
            {loading ? (
              <p>Loading...</p>
            ) : (
              memories.map((memory, index) => (
                <Grid item xs={12} md={6} lg={4} key={index}>
                  <MKBox mt={3}>
                    <SimpleBookingCard
                      image={memory.files[0].url} // Use the first image from the files array
                      title={memory.memoryName}
                      description={memory.description}
                      action={{
                        type: "internal",
                        route: `/memory/details/${memory._id}`,
                        color: "info",
                        label: "View Details",
                        onClick: () => handleViewDetails(memory._id), // Pass memory ID to navigate
                      }}
                    />
                  </MKBox>
                </Grid>
              ))
            )}
          </Grid>

          {/* Pagination Controls */}
          <MKBox mt={5}>
            <MKPagination>
              {/* Previous Page */}
              <MKPagination
                item
                disabled={currentPage === 1} // Disable on first page
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <Icon>keyboard_arrow_left</Icon>
              </MKPagination>

              {/* Display page numbers */}
              {Array.from({ length: totalPages }, (_, i) => (
                <MKPagination
                  item
                  key={i + 1}
                  active={i + 1 === currentPage} // Highlight current page
                  onClick={() => handlePageChange(i + 1)} // Fetch page by number
                >
                  {i + 1}
                </MKPagination>
              ))}

              {/* Next Page */}
              <MKPagination
                item
                disabled={currentPage === totalPages} // Disable on last page
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <Icon>keyboard_arrow_right</Icon>
              </MKPagination>
            </MKPagination>
          </MKBox>
        </Container>
      </MKBox>
    </>
  );
}

export default Blog;
