/**
=========================================================
* Material Kit 2 PRO React - v2.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
// import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Authentication layout components
import SimpleLayout from "pages/Authentication/components/SimpleLayout";
// import Separator from "pages/Authentication/components/Separator";
// import Socials from "pages/Authentication/components/Socials";

import { useAuth } from "context/AuthContext"; // Import Auth context
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

function SignInSimple() {
  const { signIn } = useAuth(); // Use the signIn function from Auth context
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null); // To handle errors

  const navigate = useNavigate();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleSignIn = async (e) => {
    const token = Cookies.get("token");
    console.log("Cookies available: ", Cookies.get()); // Check all available cookies
    console.log("Token: ", token);
    e.preventDefault();
    try {
      const response = await signIn(email, password); // Call the signIn function
      console.log("response login: ", response);
      if (response.success) {
        // console.log("cookies token: ", Cookies.get("token"));
        navigate("/");
      } else {
        console.error(response.message);
      }
    } catch (error) {
      setErrorMessage(error.message); // Set error message if login fails
    }
  };

  return (
    <SimpleLayout>
      <Card>
        <MKBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          pt={2.5}
          pb={2.875}
          px={2.5}
          textAlign="center"
          lineHeight={1}
        >
          <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MKTypography>
          <MKTypography variant="button" color="white">
            Welcome back
          </MKTypography>
        </MKBox>
        <MKBox p={3}>
          {/* Render error message if it exists */}
          {errorMessage && <MKTypography color="error">{errorMessage}</MKTypography>}
          <MKBox component="form" role="form" onSubmit={handleSignIn}>
            <MKBox mb={2}>
              <MKInput
                type="email"
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
              />
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                type="password"
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
              />
            </MKBox>
            <MKBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MKTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MKTypography>
            </MKBox>
            <MKBox mt={2} mb={1}>
              <MKButton type="submit" variant="gradient" color="info" fullWidth>
                sign in
              </MKButton>
            </MKBox>
          </MKBox>
        </MKBox>
      </Card>
    </SimpleLayout>
  );
}

export default SignInSimple;
