import { useEffect, useRef } from "react";

// typed-js
import Typed from "typed.js";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Link from "@mui/material/Link";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import bgImage from "assets/images/bg-about-us.jpg";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

function Header() {
  const typedJSRef = useRef(null);
  const navigate = useNavigate();

  // Setting up typedJS
  useEffect(() => {
    const typedJS = new Typed(typedJSRef.current, {
      strings: ["our love story", "our journey", "our future together"],
      typeSpeed: 70,
      backSpeed: 70,
      backDelay: 200,
      startDelay: 500,
      loop: true,
    });

    return () => typedJS.destroy();
  }, []);

  const handleSignOut = () => {
    Cookies.remove("token");
    navigate("/authentication/sign-in");
  };

  return (
    <MKBox component="header" position="relative">
      <MKBox
        position="absolute"
        top="1rem"
        right="1rem"
        zIndex={10}
        sx={{
          "&:hover .signOutButton": {
            opacity: 1,
            visibility: "visible",
          },
        }}
      >
        <MKButton
          className="signOutButton"
          variant="gradient"
          color="info"
          sx={{
            padding: "0.5rem 1rem",
            margin: 0,
            opacity: 0,
            visibility: "hidden",
            transition: "opacity 0.3s ease, visibility 0.3s ease", // Smooth transition
          }}
          onClick={handleSignOut}
        >
          Sign Out
        </MKButton>
      </MKBox>

      <MKBox
        display="flex"
        alignItems="center"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.5),
              rgba(gradients.info.state, 0.5)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            mx="auto"
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Celebrating <span ref={typedJSRef} />
            </MKTypography>
            <MKTypography variant="body1" color="white" mt={1} mb={6} px={{ xs: 3, lg: 6 }}>
              Every moment with you is a treasure. Today, we celebrate all the joy, love, and
              memories we have created together.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );
}

export default Header;
