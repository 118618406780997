// src/api/memory.js

import axios from "./axiosConfig";

export const createMemory = async (memoryData) => {
  try {
    console.log("memoryData in memory API: ", memoryData);
    // Log the contents of formData
    for (let [key, value] of memoryData.entries()) {
      console.log(`${key}:`, value);
    }
    const response = await axios.post("/memory/create", memoryData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("response.data: ", response.data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getMemory = async (id = null) => {
  try {
    const url = id ? `/memory/${id}` : "/memory"; // If id is provided, include it in the URL
    const response = await axios.get(url);
    console.log("response in getMemory api: ", response.data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// eslint-disable-next-line no-unused-vars
export const getMemoryPagination = async (id = null, params = null) => {
  console.log("START getMemory IN api/memory!");
  try {
    // console.log(`id: ${id} || params: ${params}`);
    const response = await axios.get("/memory/pagination", {
      params, // Send pagination parameters (limit, page)
    });
    // console.log("response in getMemory api: ", response.data);
    console.log("END getMemory IN api/memory!");
    return response.data;
  } catch (error) {
    console.log("END getMemory IN api/memory!");
    throw error.response.data;
  }
};
