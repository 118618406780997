import { useEffect, useRef, useState } from "react";
import Rellax from "rellax";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import Information from "pages/Blogs/SingleArticle/sections/Information";
import { useParams } from "react-router-dom";
import { useMemory } from "context/MemoryContext";
import { useNavigate } from "react-router-dom";

function MemoryDetails() {
  const headerRef = useRef(null);
  const { id } = useParams();
  const [memory, setMemory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { getMemory } = useMemory();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && headerRef.current) {
      const parallax = new Rellax(headerRef.current, {
        speed: -6,
      });
      return () => parallax.destroy();
    }
  }, [isLoading]);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await getMemory(id);
        setMemory(response);
      } catch (error) {
        console.error("Error fetching memory details:", error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [id, getMemory]);

  const handleBack = () => {
    navigate("/home");
  };

  if (isLoading) {
    return (
      <Container>
        <MKTypography variant="h5" textAlign="center" py={20}>
          Loading...
        </MKTypography>
      </Container>
    );
  }

  const getBackgroundStyles = ({
    functions: { linearGradient, rgba },
    palette: { gradients },
    breakpoints,
  }) => {
    if (!memory?.data?.files?.length) return { backgroundImage: "none" };

    const imageUrl = memory.data.files[0].url;
    const gradientOverlay = linearGradient(
      rgba(gradients.dark.main, 0.5),
      rgba(gradients.dark.state, 0.5)
    );

    return {
      position: "relative",
      overflow: "hidden",
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: `${gradientOverlay}, url(${imageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        zIndex: 0,
        [breakpoints.down("md")]: {
          backgroundSize: "contain",
          backgroundPosition: "center center",
          height: "100%",
          transform: "scale(1.2)", // Slightly scale up to prevent white edges
        },
      },
      // Container styles
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: {
        xs: "70vh",
        md: "85vh",
      },
    };
  };

  return (
    <>
      <MKBox ref={headerRef} width="100%" sx={(theme) => getBackgroundStyles(theme)}>
        <MKBox
          position="absolute"
          top="1rem"
          left="1rem"
          zIndex={10}
          sx={{
            "&:hover .signOutButton": {
              opacity: 1,
              visibility: "visible",
            },
          }}
        >
          <MKButton
            className="signOutButton"
            variant="gradient"
            color="light"
            size="small"
            sx={{
              padding: "0.5rem 1rem",
              margin: 0,
              opacity: 0,
              visibility: "hidden",
              transition: "opacity 0.3s ease, visibility 0.3s ease", // Smooth transition
            }}
            onClick={handleBack}
          >
            Back
          </MKButton>
        </MKBox>
        <Container sx={{ position: "relative", zIndex: 1 }}>
          <Grid
            container
            sx={{
              minHeight: { xs: "60vh", md: "70vh" },
              py: { xs: 4, md: 0 },
            }}
          >
            <Grid
              item
              xs={12}
              lg={7}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <MKTypography
                variant="h1"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                    textAlign: "center",
                  },
                  textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                })}
              >
                {memory?.memoryName || " "}
              </MKTypography>

              <MKTypography
                variant="body1"
                color="white"
                opacity={0.8}
                mb={2}
                sx={({ breakpoints }) => ({
                  [breakpoints.down("md")]: {
                    textAlign: "center",
                    mr: 0,
                    pr: 0,
                    px: 2,
                  },
                  [breakpoints.up("md")]: {
                    mr: 6,
                    pr: 6,
                  },
                  textShadow: "1px 1px 2px rgba(0,0,0,0.5)",
                })}
              >
                {memory?.description || " "}
              </MKTypography>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: { xs: -4, md: -8 },
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          minHeight: { xs: "40vh", md: "60vh" },
        }}
      >
        {memory && <Information memory={memory} />}
      </Card>
    </>
  );
}

export default MemoryDetails;
