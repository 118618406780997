// import { useEffect } from "react";

// // react-router components
// import { Routes, Route, useNavigate, Navigate, useLocation } from "react-router-dom";

// // @mui material components
// import { ThemeProvider } from "@mui/material/styles";
// import CssBaseline from "@mui/material/CssBaseline";

// // Material Kit 2 PRO React themes
// import theme from "assets/theme";
// import Home from "layouts/pages/home";
// import Cookies from "js-cookie";
// import { jwtDecode } from "jwt-decode"; // Import the jwt-decode library

// // Material Kit 2 PRO React routes
// import routes from "routes";

// export default function App() {
//   const { pathname } = useLocation();
//   const navigate = useNavigate();

//   // Setting page scroll to 0 when changing the route
//   useEffect(() => {
//     document.documentElement.scrollTop = 0;
//     document.scrollingElement.scrollTop = 0;

//     // New middleware-like logic, e.g., checking authentication
//     const token = Cookies.get("token");

//     if (token) {
//       try {
//         const decodedToken = jwtDecode(token); // Decode the token

//         // Check if the token has expired
//         const currentTime = Date.now() / 1000; // Get the current time in seconds
//         if (decodedToken.exp < currentTime) {
//           console.log("Token expired, redirecting to login");
//           Cookies.remove("token"); // Remove the expired token
//           navigate("/authentication/sign-in");
//         }
//       } catch (error) {
//         console.error("Invalid token:", error);
//         Cookies.remove("token"); // Remove the invalid token
//         navigate("/authentication/sign-in");
//       }
//     } else if (pathname !== "/authentication/sign-in") {
//       // Redirect if no token and not on the login page
//       console.log("No token, redirecting to login");
//       navigate("/authentication/sign-in");
//     }

//     // Clear token when navigating to the sign-in page
//     if (pathname === "/authentication/sign-in") {
//       Cookies.remove("token");
//     }
//   }, [pathname, navigate]);

//   const getRoutes = (allRoutes) =>
//     allRoutes.map((route) => {
//       if (route.collapse) {
//         return getRoutes(route.collapse);
//       }

//       if (route.route) {
//         return <Route exact path={route.route} element={route.component} key={route.key} />;
//       }

//       return null;
//     });

//   return (
//     <ThemeProvider theme={theme}>
//       <CssBaseline />
//       <Routes>
//         {getRoutes(routes)}
//         <Route path="/home" element={<Home />} />
//         <Route path="*" element={<Navigate to="/home" />} />
//       </Routes>
//     </ThemeProvider>
//   );
// }

//! = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

// import { useEffect } from "react";
// import { Routes, Route, useNavigate, Navigate, useLocation } from "react-router-dom";
// import { ThemeProvider } from "@mui/material/styles";
// import CssBaseline from "@mui/material/CssBaseline";
// import theme from "assets/theme";
// // import Home from "layouts/pages/home";
// import Cookies from "js-cookie";
// import { jwtDecode } from "jwt-decode";
// import routes from "routes";

// export default function App() {
//   const { pathname } = useLocation();
//   const navigate = useNavigate();

//   useEffect(() => {
//     document.documentElement.scrollTop = 0;
//     document.scrollingElement.scrollTop = 0;

//     const checkAuth = () => {
//       const token = Cookies.get("token");
//       const isAuthPage = pathname === "/authentication/sign-in";

//       if (!token && !isAuthPage) {
//         navigate("/authentication/sign-in", { replace: true });
//         return;
//       }

//       if (token) {
//         try {
//           const decodedToken = jwtDecode(token);
//           const currentTime = Date.now() / 1000;

//           if (decodedToken.exp < currentTime) {
//             Cookies.remove("token");
//             if (!isAuthPage) {
//               navigate("/authentication/sign-in", { replace: true });
//             }
//           } else if (isAuthPage) {
//             // If user is authenticated and on login page, redirect to home
//             // navigate("/home", { replace: true });
//             navigate("/home", { replace: true });
//           }
//         } catch (error) {
//           console.error("Invalid token:", error);
//           Cookies.remove("token");
//           if (!isAuthPage) {
//             navigate("/authentication/sign-in", { replace: true });
//           }
//         }
//       }
//     };

//     checkAuth();
//   }, [pathname, navigate]);

//   const getRoutes = (allRoutes) =>
//     allRoutes.map((route) => {
//       if (route.collapse) {
//         return getRoutes(route.collapse);
//       }

//       if (route.route) {
//         // Add authentication check for protected routes
//         if (route.protected) {
//           return (
//             <Route
//               exact
//               path={route.route}
//               element={
//                 Cookies.get("token") ? (
//                   route.component
//                 ) : (
//                   <Navigate to="/authentication/sign-in" replace />
//                 )
//               }
//               key={route.key}
//             />
//           );
//         }
//         return <Route exact path={route.route} element={route.component} key={route.key} />;
//       }

//       return null;
//     });

//   return (
//     <ThemeProvider theme={theme}>
//       <CssBaseline />
//       <Routes>
//         {getRoutes(routes)}
//         <Route path="/" element={<Navigate to="/home" replace />} />
//         <Route path="*" element={<Navigate to="/home" replace />} />
//       </Routes>
//     </ThemeProvider>
//   );
// }

//! = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

import { useEffect } from "react";
import { Routes, Route, useNavigate, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import routes from "routes";

export default function App() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;

    const checkAuth = () => {
      const token = Cookies.get("token");
      const isAuthPage = pathname === "/authentication/sign-in";

      if (!token && !isAuthPage) {
        navigate("/authentication/sign-in", { replace: true });
        return;
      }

      if (pathname === "/authentication/sign-in") {
        Cookies.remove("token");
      }

      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000;

          if (decodedToken.exp < currentTime) {
            Cookies.remove("token");
            if (!isAuthPage) {
              navigate("/authentication/sign-in", { replace: true });
            }
          } else if (isAuthPage) {
            navigate("/home", { replace: true });
          }
        } catch (error) {
          console.error("Invalid token:", error);
          Cookies.remove("token");
          if (!isAuthPage) {
            navigate("/authentication/sign-in", { replace: true });
          }
        }
      }
    };

    checkAuth();
  }, [pathname, navigate]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        if (route.protected) {
          return (
            <Route
              exact
              path={route.route}
              element={
                Cookies.get("token") ? (
                  route.component
                ) : (
                  <Navigate to="/authentication/sign-in" replace />
                )
              }
              key={route.key}
            />
          );
        }
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route path="/" element={<Navigate to="/home" replace />} />
        <Route path="*" element={<Navigate to="/home" replace />} />
      </Routes>
    </ThemeProvider>
  );
}
