/*
=========================================================
* Material Kit 2 PRO React - v2.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Divider from "@mui/material/Divider";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
// import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

import WeddingCountdown from "./data/weddingCountdown";
import DaysTogetherCounter from "./data/daysTogetherCounter";
import AgeCounter from "./data/ageCounter";

function Counters() {
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          {/* Wedding Countdown */}
          {/* <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={95} // Adjust to reflect actual countdown
              title="Days until Our Wedding"
              description="Just a few more days until we say 'I do'."
            />
          </Grid> */}
          <WeddingCountdown />
          {/* How long you've been together */}
          {/* <Grid item xs={12} md={4} display="flex">
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
            <DefaultCounterCard
              count={1000} // Adjust this to reflect the number of days
              title="Days Together"
              description="Celebrating 1000+ days of love and happiness."
            />
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
          </Grid> */}
          <DaysTogetherCounter />
          {/* A meaningful counter */}
          {/* <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={24}
              title="Years Old"
              // title="A Day Just for You"
              description="Today is all about celebrating you."
            />
          </Grid> */}
          <AgeCounter />
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Counters;
