/*
=========================================================
* Material Kit 2 PRO React - v2.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
// import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
// import Icon from "@mui/material/Icon";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import PropTypes from "prop-types"; // Import PropTypes

function NotificationModal({ open, onClose }) {
  return (
    <MKBox component="section" py={6}>
      <Container>
        <Modal open={open} onClose={onClose} sx={{ display: "grid", placeItems: "center" }}>
          <Slide direction="down" in={open} timeout={500}>
            <MKBox
              position="relative"
              width="500px"
              display="flex"
              flexDirection="column"
              borderRadius="xl"
              variant="gradient"
              bgColor="error"
              shadow="sm"
            >
              <MKBox
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                py={3}
                px={2}
              >
                <MKTypography variant="h6" color="white">
                  Terms and Conditions
                </MKTypography>
                <CloseIcon
                  color="white"
                  fontSize="medium"
                  sx={{ cursor: "pointer" }}
                  onClick={onClose}
                />
              </MKBox>
              <Divider light sx={{ my: 0 }} />
              <MKBox p={6} textAlign="center" color="white">
                <MKTypography variant="body2" color="white" opacity={0.8} mb={2}>
                  <strong>1. Love Is Mandatory:</strong>
                  <br />
                  By booking this appointment, you acknowledge that love and affection are
                  non-negotiable. Failure to provide a selfie with a smile will result in a
                  reschedule.
                </MKTypography>
                <MKTypography variant="body2" color="white" opacity={0.8} mb={2}>
                  <strong>2. 100% Fun Guarantee:</strong>
                  <br />
                  This appointment comes with a 100% fun guarantee. If you&rsquo;re not laughing,
                  you&rsquo;re doing it wrong!
                </MKTypography>
                <MKTypography variant="body2" color="white" opacity={0.8} mb={2}>
                  <strong>3. No Cancellation:</strong>
                  <br />
                  Once you&rsquo;ve booked me, there&rsquo;s no backing out! I&rsquo;m officially on
                  the hook for the date.
                </MKTypography>
                <MKTypography variant="body2" color="white" opacity={0.8} mb={2}>
                  <strong>4. Unlimited Laughs Policy:</strong>
                  <br />
                  Our time together should include at least one funny story. If laughter isn&rsquo;t
                  heard, additional tickles will be provided!
                </MKTypography>
                <MKTypography variant="body2" color="white" opacity={0.8} mb={2}>
                  <strong>5. Happy Endings:</strong>
                  <br />
                  Every appointment must end with a selfie (or two) and a promise of more adventures
                  in the future.
                </MKTypography>
                <MKTypography variant="body2" color="white" opacity={0.8} mb={2}>
                  <strong>6. Change of Plans:</strong>
                  <br />
                  If something more fun comes up (like a spontaneous food hunting), this appointment
                  may be rescheduled for a later date.
                </MKTypography>
              </MKBox>
              <Divider light sx={{ my: 0 }} />
              <MKBox display="flex" justifyContent="center" py={2} px={1.5}>
                <MKButton color="white" onClick={onClose}>
                  I agree to these terms!
                </MKButton>
                {/* <MKButton variant="text" color="white" onClick={onClose}>
                  close
                </MKButton> */}
              </MKBox>
            </MKBox>
          </Slide>
        </Modal>
      </Container>
    </MKBox>
  );
}

// Define prop types for the component
NotificationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NotificationModal;
