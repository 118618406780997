import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKAlert from "components/MKAlert";

// File Dropzone
import FileDropzone from "./FileDropzone"; // Import your dropzone

import { useMemory } from "context/MemoryContext";

// Notification Modal
import NotificationModal from "layouts/sections/attention-catchers/modals/components/NotificationModal";

// eslint-disable-next-line no-undef
const moment = require("moment");

function CreateMemoryForm() {
  const [successMessage, setSuccessMessage] = useState(""); // Success message state
  const [errorMessage, setErrorMessage] = useState(""); // Error message state

  const currentDate = moment().format("YYYY-MM-DD");

  const { createMemory } = useMemory();

  const maxFiles = 1;

  // State to handle form inputs
  const [memoryName, setMemoryName] = useState("");
  const [description, setDescription] = useState("");
  const [story, setStory] = useState("");
  const [date, setDate] = useState(currentDate);
  const [files, setFiles] = useState([]); // To store the selected files
  const [fileError, setFileError] = useState(false); // files error state
  // State for validation errors
  const [errors, setErrors] = useState({
    memoryName: false,
    description: false,
    story: false,
    date: false,
    file: false,
  });

  // Handle modal visibility
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => setModalOpen(!modalOpen);

  // Handle file selection from the Dropzone
  const handleFileSelect = (selectedFiles) => {
    setFiles(selectedFiles); // Store the selected files, properly resetting on each upload
    setFileError(false);
  };

  // Validate form fields before submitting
  const validateForm = () => {
    const newErrors = {
      memoryName: memoryName === "", // Error if empty
      description: description === "", // Error if description is empty
      story: story.length < 20, // Error if story is empty
      date: date === "", // Error if date is empty
      file: files.length === 0, // Ensure a file is selected
    };
    console.log("newErrors: ", newErrors);
    setFileError(newErrors.file);
    setErrorMessage("");
    setErrors(newErrors);
    setSuccessMessage("");
    return !Object.values(newErrors).includes(true); // Return true if no errors
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    setSuccessMessage("");
    setErrorMessage("");

    // Validate before submitting
    if (!validateForm()) {
      setSuccessMessage("");
      setErrorMessage("Form has errors!");
      console.log("Form has errors.");
      if (!files) setFileError(true);
      return;
    }

    const memoryData = {
      memoryName,
      description,
      story,
      date,
      tag: "memory",
    };

    console.log("memoryData: ", memoryData);
    console.log("Files to upload: ", files); // You can now send this files to backend

    const formData = new FormData();

    formData.append("memoryName", memoryData.memoryName);
    formData.append("description", memoryData.description);
    formData.append("story", memoryData.story);
    formData.append("date", memoryData.date);
    formData.append("tag", memoryData.tag);

    files.forEach((file) => {
      formData.append("files", file);
    });

    // Log the contents of formData
    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }

    try {
      const response = await createMemory(formData);
      console.log("response create memory: ", response);
      if (response.success) {
        console.log("Memory created successfully!");
        setSuccessMessage("Memory created successfully!");
        setMemoryName("");
        setDescription("");
        setStory("");
        setDate("");
        setFiles("");
        setFileError("");
      } else {
        console.error(response.message);
        setSuccessMessage(""); // Clear success message on error
        setErrorMessage(response.message || "Failed to create memory.");
      }
    } catch (error) {
      console.error("Error creating memory: ", error);
    }
  };

  return (
    <MKBox component="section" py={12}>
      <Container>
        {successMessage && (
          <MKAlert color="success" dismissible>
            {successMessage}
          </MKAlert>
        )}
        {errorMessage && (
          <MKAlert color="error" dismissible>
            {errorMessage}
          </MKAlert>
        )}
        <Grid container item justifyContent="center" xs={10} lg={7} mx="auto" textAlign="center">
          <MKTypography variant="h3" mb={1}>
            Create New Memory
          </MKTypography>
        </Grid>
        <Grid container item xs={12} lg={7} sx={{ mx: "auto" }}>
          <MKBox
            width="100%"
            component="form"
            method="post"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <MKBox p={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <MKInput
                    variant="standard"
                    label="Name"
                    fullWidth
                    value={memoryName}
                    onChange={(e) => setMemoryName(e.target.value)}
                    error={errors.memoryName} // Error state
                    success={!errors.memoryName && memoryName !== ""} // Success state
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput
                    variant="standard"
                    label="Description"
                    fullWidth
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    error={errors.description} // Error state
                    success={!errors.description && description !== ""} // Success state
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput
                    variant="standard"
                    label="Story (min 20 characters)"
                    fullWidth
                    value={story}
                    multiline
                    rows={5}
                    onChange={(e) => setStory(e.target.value)}
                    error={errors.story} // Error state
                    success={!errors.story && story !== ""} // Success state
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput
                    variant="standard"
                    type="date"
                    label="Date"
                    fullWidth
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    error={errors.date} // Error state
                    success={!errors.date && date !== ""} // Success state
                  />
                </Grid>
                <Grid item xs={12}>
                  <FileDropzone onFileSelect={handleFileSelect} maxFiles={maxFiles} />
                  {fileError && <p style={{ color: "red" }}>Please upload atleast one file.</p>}
                </Grid>
              </Grid>
              <Grid container item justifyContent="center" xs={12} my={2}>
                <MKButton type="submit" variant="gradient" color="dark" fullWidth>
                  Create Memory
                </MKButton>
              </Grid>
            </MKBox>
          </MKBox>
        </Grid>
      </Container>
      <NotificationModal open={modalOpen} onClose={toggleModal} />
    </MKBox>
  );
}

export default CreateMemoryForm;
