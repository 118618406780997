// src/context/AuthContext.js
import React, { createContext, useState, useContext } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { login as apiLogin } from "../api/auth"; // Import the API login function
import Cookies from "js-cookie"; // Import js-cookie

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const signIn = async (email, password) => {
    try {
      const data = await apiLogin(email, password); // Call the API login function
      console.log("data in signIn: ", data);

      if (data.success && data.token) {
        setUser(data.user); // Set the user state with the response user data
        Cookies.set("token", data.token, { expires: 1 }); // Set the token in cookies for 1 day
      }

      return data;
    } catch (error) {
      console.error("Login failed:", error.message); // Handle errors appropriately
      // You can also add error state management if needed
    }
  };

  const signOut = () => {
    setUser(null);
    localStorage.removeItem("token"); // Remove the token from local storage
  };

  return <AuthContext.Provider value={{ user, signIn, signOut }}>{children}</AuthContext.Provider>;
};

// Add propTypes for the AuthProvider
AuthProvider.propTypes = {
  children: PropTypes.node.isRequired, // Validate that children is a required prop
};

export const useAuth = () => useContext(AuthContext);
