// src/context/AppointmentContext.js
import React, { createContext, useContext } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { createAppointment as apiCreateAppointment } from "../api/appointment"; // Import the API login function

const AppointmentContext = createContext();

export const AppointmentProvider = ({ children }) => {
  //   const [appointment, setAppointment] = useState(null);

  const createAppointment = async (appointmentData) => {
    try {
      const data = await apiCreateAppointment(appointmentData);
      console.log("data in create appointment: ", data);
      return data;
    } catch (error) {
      console.error("Failed to create appointment: ", error);
    }
  };

  return (
    <AppointmentContext.Provider value={{ createAppointment }}>
      {children}
    </AppointmentContext.Provider>
  );
};

AppointmentProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAppointment = () => useContext(AppointmentContext);
