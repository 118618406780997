import React, { useState, useEffect } from "react";
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

const DaysTogetherCounter = () => {
  const [daysTogether, setDaysTogether] = useState(0);

  useEffect(() => {
    // Start date (when you first met)
    const startDate = new Date("2015-01-01");

    // Current date (today's date)
    const today = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = today - startDate;

    // Convert time difference from milliseconds to days
    const daysSinceStart = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Round down

    // Update the state with the number of days together
    setDaysTogether(daysSinceStart);
  }, []); // Empty dependency array ensures this runs once on mount

  return (
    <Grid item xs={12} md={4} display="flex">
      <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
      <DefaultCounterCard
        count={daysTogether} // Dynamic count value for days together
        title="Days Together"
        description={`Celebrating ${daysTogether}+ days of love and happiness.`}
      />
      <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
    </Grid>
  );
};

export default DaysTogetherCounter;
