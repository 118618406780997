import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import MKAlert from "components/MKAlert";

// Notification Modal
import NotificationModal from "layouts/sections/attention-catchers/modals/components/NotificationModal";

import { useAppointment } from "context/AppointmentContext";

// eslint-disable-next-line no-undef
const moment = require("moment");

function CreateAppointmentForm() {
  const currentDate = moment().format("YYYY-MM-DD");
  const { createAppointment } = useAppointment();

  // Handle modal visibility
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => setModalOpen(!modalOpen);

  // State to handle form inputs
  const [appointmentName, setAppointmentName] = useState("");
  const [type, setType] = useState("");
  const [date, setDate] = useState(currentDate);
  const [budget, setBudget] = useState("");
  const [notes, setNotes] = useState("");
  const [checked, setChecked] = useState(false); // For terms & conditions agreement

  // State for validation errors
  const [errors, setErrors] = useState({
    appointmentName: false,
    type: false,
    date: false,
  });

  const [successMessage, setSuccessMessage] = useState(""); // Success message state
  const [errorMessage, setErrorMessage] = useState(""); // Error message state

  const handleChecked = () => setChecked(!checked);

  // Validate form fields before submitting
  const validateForm = () => {
    const newErrors = {
      appointmentName: appointmentName === "", // Error if empty
      type: type === "", // Error if type is empty
      date: date === "", // Error if date is empty
    };
    setErrors(newErrors);
    return !Object.values(newErrors).includes(true); // Return true if no errors
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Clear messages at the beginning of submission
    setSuccessMessage("");
    setErrorMessage("");

    // Validate before submitting
    if (!validateForm()) {
      console.log("Form has errors.");
      setErrorMessage("Form has errors!"); // Set error message
      return;
    }

    const appointmentData = {
      appointmentName,
      type,
      date,
      budget: budget ? parseFloat(budget) : 0, // Ensure budget is a number
      notes,
    };

    console.log("appointmentData: ", appointmentData);

    // try {
    //   // POST request to create an appointment
    //   const response = await createAppointment(appointmentData);
    //   console.log("response create appointment: ", response);
    //   if (response.success) {
    //     console.log("Appointment created successfully!");
    //   } else {
    //     console.error(response.message);
    //   }
    //   // Optionally, you can reset the form or show a success message
    // } catch (error) {
    //   console.error("Error creating appointment:", error);
    // }

    try {
      const response = await createAppointment(appointmentData);
      console.log("response create appointment: ", response);

      if (response.success) {
        setSuccessMessage("Appointment created successfully!"); // Set success message
        setAppointmentName("");
        setType("");
        setDate(currentDate);
        setBudget("");
        setNotes("");
        setErrorMessage(""); // Clear success message on error
      } else {
        setErrorMessage(response.message || "Failed to create appointment.");
        setSuccessMessage(""); // Clear success message on error
      }
    } catch (error) {
      console.error("Error creating appointment:", error);
    }
  };

  return (
    <MKBox component="section" py={12}>
      <Container>
        {successMessage && (
          <MKAlert color="success" dismissible>
            {successMessage}
          </MKAlert>
        )}
        {errorMessage && (
          <MKAlert color="error" dismissible>
            {errorMessage}
          </MKAlert>
        )}
        <Grid container item justifyContent="center" xs={10} lg={7} mx="auto" textAlign="center">
          <MKTypography variant="h3" mb={1}>
            Create Appointment
          </MKTypography>
        </Grid>
        <Grid container item xs={12} lg={7} sx={{ mx: "auto" }}>
          <MKBox
            width="100%"
            component="form"
            method="post"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <MKBox p={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <MKInput
                    variant="standard"
                    label="Name"
                    fullWidth
                    value={appointmentName}
                    onChange={(e) => setAppointmentName(e.target.value)}
                    error={errors.appointmentName} // Error state
                    success={!errors.appointmentName && appointmentName !== ""} // Success state
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput
                    variant="standard"
                    label="Type of Appointment"
                    select
                    SelectProps={{ native: true }}
                    fullWidth
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    error={errors.type} // Error state
                    success={!errors.type && type !== ""} // Success state
                  >
                    <option value="" defaultValue={""} disabled></option>{" "}
                    {/* Default disabled option */}
                    <option value="hangout">Hangout</option>
                    <option value="dinner">Dinner</option>
                    <option value="travel">Travel</option>
                    <option value="movie">Movie</option>
                    <option value="outdoor">Outdoor</option>
                  </MKInput>
                </Grid>
                <Grid item xs={12}>
                  <MKInput
                    variant="standard"
                    type="date"
                    label="Date"
                    fullWidth
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    error={errors.date} // Error state
                    success={!errors.date && date !== ""} // Success state
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput
                    variant="standard"
                    label="Budget - RM (Optional)"
                    type="number"
                    fullWidth
                    value={budget}
                    onChange={(e) => setBudget(e.target.value)}
                    inputProps={{ min: 0 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput
                    variant="standard"
                    label="Notes (Optional)"
                    multiline
                    fullWidth
                    rows={4}
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} alignItems="center" ml={-1}>
                  <Switch checked={checked} onChange={handleChecked} />
                  <MKTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    ml={-1}
                    sx={{ cursor: "pointer", userSelect: "none" }}
                    onClick={handleChecked}
                  >
                    &nbsp;&nbsp;I agree to the&nbsp;
                  </MKTypography>
                  <MKTypography
                    component="a"
                    href="#"
                    variant="button"
                    fontWeight="regular"
                    color="dark"
                    onClick={toggleModal} // Open modal on click
                  >
                    Terms and Conditions
                  </MKTypography>
                </Grid>
              </Grid>
              <Grid container item justifyContent="center" xs={12} my={2}>
                <MKButton
                  type="submit"
                  variant="gradient"
                  color="dark"
                  fullWidth
                  disabled={!checked} // Disable button if not checked
                >
                  Create Appointment
                </MKButton>
              </Grid>
            </MKBox>
          </MKBox>
        </Grid>
      </Container>
      <NotificationModal open={modalOpen} onClose={toggleModal} />
    </MKBox>
  );
}

export default CreateAppointmentForm;
