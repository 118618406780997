// src/api/appointment.js

import axios from "./axiosConfig";

export const createAppointment = async (appointmentData) => {
  try {
    const response = await axios.post("/appointment/create", {
      appointmentData,
    });
    console.log("response.data: ", response.data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
