import React, { useState, useEffect } from "react";
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import Grid from "@mui/material/Grid";

const WeddingCountdown = () => {
  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    // Current date (today's date) at 12:00 AM
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset time to 12:00 AM

    // Wedding date (4th January 2025) at 12:00 AM
    const weddingDate = new Date("2025-01-04");
    weddingDate.setHours(0, 0, 0, 0); // Set wedding date time to 12:00 AM

    // Calculate the time difference in milliseconds
    const timeDifference = weddingDate - today;

    // Convert time difference from milliseconds to days
    const daysUntilWedding = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    // Update the countdown state
    setCountdown(daysUntilWedding);
  }, []); // Empty dependency array means this runs once on component mount

  return (
    <Grid item xs={12} md={4}>
      <DefaultCounterCard
        count={countdown} // Dynamic countdown value
        title="Days until Our Wedding"
        description="Just a few more days until we say 'I do'."
      />
    </Grid>
  );
};

export default WeddingCountdown;
