/* eslint-disable no-undef */
// src/api/axiosConfig.js
import axios from "axios";
import Cookies from "js-cookie"; // Or use localStorage
// eslint-disable-next-line no-undef

// Set the default base URL (update with your actual backend URL)
// axios.defaults.baseURL = "http://localhost:5000/api";
// axios.defaults.withCredentials = true; // Enable withCredentials for all requests

// console.log("process: ", process.env.REACT_APP_BACKEND_BASE_URL);
// eslint-disable-next-line no-undef
// const baseUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}/api`;
console.log("process.env.NODE_ENV: ", process.env.NODE_ENV);
// Create an Axios instance
const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "/api" // Just use /api since frontend and backend are on same domain
      : "http://localhost:5000/api", // for development
  withCredentials: true, // If you're using cookies for authentication
});

// Add a request interceptor to include token in every request
api.interceptors.request.use(
  (config) => {
    // Get the token from localStorage or Cookies
    const token = Cookies.get("token");
    // console.log("token in axios interceptor: ", token);

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`; // Set token in Authorization header
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
