/**
=========================================================
* Material Kit 2 PRO React - v2.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import React from "react";
// import * as ReactDOMClient from "react-dom/client";
// import { BrowserRouter } from "react-router-dom";
// import { AuthProvider } from "./context/AuthContext";
// import { AppointmentProvider } from "./context/AppointmentContext";
// import { MemoryProvider } from "context/MemoryContext";
// import App from "App";

// const container = document.getElementById("root");
// const root = ReactDOMClient.createRoot(container);

// root.render(
//   <BrowserRouter basename="/">
//     <AuthProvider>
//       <AppointmentProvider>
//         <MemoryProvider>
//           <App />
//         </MemoryProvider>
//       </AppointmentProvider>
//     </AuthProvider>
//   </BrowserRouter>
// );

import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { AppointmentProvider } from "./context/AppointmentContext";
import { MemoryProvider } from "context/MemoryContext";
import App from "App";

const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);

root.render(
  <BrowserRouter>
    <AuthProvider>
      <AppointmentProvider>
        <MemoryProvider>
          <App />
        </MemoryProvider>
      </AppointmentProvider>
    </AuthProvider>
  </BrowserRouter>
);
